// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WRCeeHn8r", "Vi4AF4Tyw"];

const variantClassNames = {Vi4AF4Tyw: "framer-v-tggiyh", WRCeeHn8r: "framer-v-1i3tnf6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "WRCeeHn8r", "Variant 2": "Vi4AF4Tyw"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, PoM4YLkh3: tap ?? props.PoM4YLkh3, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "WRCeeHn8r"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PoM4YLkh3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WRCeeHn8r", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap13y7djd = activeVariantCallback(async (...args) => {
if (PoM4YLkh3) {
const res = await PoM4YLkh3(...args);
if (res === false) return false;
}
setVariant("Vi4AF4Tyw")
})

const onTap1icdlwk = activeVariantCallback(async (...args) => {
if (PoM4YLkh3) {
const res = await PoM4YLkh3(...args);
if (res === false) return false;
}
setVariant("WRCeeHn8r")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QC95S", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1i3tnf6", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"WRCeeHn8r"} onTap={onTap13y7djd} ref={ref} style={{...style}} {...addPropertyOverrides({Vi4AF4Tyw: {"data-framer-name": "Variant 2", onTap: onTap1icdlwk}}, baseVariant, gestureVariant)}><motion.div className={"framer-1pe4v3d"} layoutDependency={layoutDependency} layoutId={"rsM8ulHLY"}><motion.div className={"framer-1omqxgl"} layoutDependency={layoutDependency} layoutId={"V3_IAelbG"} style={{backgroundColor: "rgb(118, 118, 127)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, rotate: 0}} variants={{Vi4AF4Tyw: {rotate: 45}}}/><motion.div className={"framer-1hcdr0e"} layoutDependency={layoutDependency} layoutId={"J_zdV6iaj"} style={{backgroundColor: "rgb(118, 118, 127)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, rotate: 0}} variants={{Vi4AF4Tyw: {rotate: -45}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QC95S [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QC95S .framer-10bwfaw { display: block; }", ".framer-QC95S .framer-1i3tnf6 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 32px; }", ".framer-QC95S .framer-1pe4v3d { flex: none; height: 14px; overflow: visible; position: relative; width: 20px; }", ".framer-QC95S .framer-1omqxgl { flex: none; height: 3px; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-QC95S .framer-1hcdr0e { bottom: 0px; flex: none; height: 3px; left: 0px; overflow: visible; position: absolute; right: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QC95S .framer-1i3tnf6 { gap: 0px; } .framer-QC95S .framer-1i3tnf6 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QC95S .framer-1i3tnf6 > :first-child { margin-left: 0px; } .framer-QC95S .framer-1i3tnf6 > :last-child { margin-right: 0px; } }", ".framer-QC95S.framer-v-tggiyh .framer-1i3tnf6 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-QC95S.framer-v-tggiyh .framer-1omqxgl, .framer-QC95S.framer-v-tggiyh .framer-1hcdr0e { bottom: 6px; height: unset; left: unset; top: 5px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Vi4AF4Tyw":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PoM4YLkh3":"tap"}
 */
const FramerFFxPirt4X: React.ComponentType<Props> = withCSS(Component, css, "framer-QC95S") as typeof Component;
export default FramerFFxPirt4X;

FramerFFxPirt4X.displayName = "Icons/AnimatedMenuBurger Copy";

FramerFFxPirt4X.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerFFxPirt4X, {variant: {options: ["WRCeeHn8r", "Vi4AF4Tyw"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, PoM4YLkh3: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerFFxPirt4X, [])